import React from "react";
import { DialogTitle, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from '@mui/styles';
import EnhancedIconButton from "../enhanced/EnhancedIconButton";

function EnhancedDialogTitle(props) {
  const classes = useStyles();
  const { handleClose, nameLabel } = props;

  return (
    <>
      <DialogTitle aria-labelledby="form-dialog-title">
        <Typography className={classes.title}>{nameLabel}</Typography>
        <EnhancedIconButton
          className={classes.closeButton}
          ariaLabel="close"
          tooltip="Close"
          Icon={Close}
          onClick={handleClose}
        />
      </DialogTitle>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    float: "left",
    padding: "0.8em"
  },
  closeButton: {
    float: "right",
    color: theme.palette.grey[500]
  }
}));

export default EnhancedDialogTitle;
