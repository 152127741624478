import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import "@fortawesome/fontawesome-free/css/all.css";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import csLocale from "@fullcalendar/core/locales/cs";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridMonth from "@fullcalendar/daygrid"
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from "react-redux";
import Agenda from "./Agenda";
import { fetchDailyNoteBy } from "../../features/dailyNoteSlice";
import { fetchEventByAsync, fetchEventsAsync } from "../../features/reservationSlice";
import alertHelper from "../../helpers/alertHelper";
import { getSessionCheckedRooms, getSessionRooms } from "../../helpers/sessionHelper";
import "src/styles/fullcalendar-vars.css";
import GrantedAuthHelper from "../../helpers/GrantedAuthHelper";
import SearchForm from "./SearchForm";
import AllDayModal from "./AllDayModal";
import HourModal from "./hourModal/HourModal";
import AddHourModal from "./hourModal/AddHourModal";
import DailyNoteDialog from "./DailyNoteDialog";

const Calendar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const calendar = useSelector((state) => state.reservation.calendar);

  const [date, setDate] = useState(false);
  const [selected, setSelected] = useState(null);
  const [calendarEvents, setCalendarEvents] = useState(calendar);
  useEffect(() => {
    setCalendarEvents(calendar);
  }, [calendar]);

  useEffect(() => {
    if (Object.keys(getSessionRooms()).length !== 0) dispatch(fetchEventsAsync());
  }, [dispatch]);

  const [openAllDayModal, setOpenAllDayModal] = useState(false);
  const [openAddHourModal, setAddOpenHourModal] = useState(false);
  function handleDateClick(clickInfo) {
    if (!GrantedAuthHelper.hasAuthority('calendar.edit')) {
      return false;
    }

    if (clickInfo.allDay && getSessionCheckedRooms().length === 1) {
      setOpenAllDayModal(true);
      setDate(clickInfo.dateStr);
    } else if (!clickInfo.allDay && getSessionCheckedRooms().length === 1) {
      setAddOpenHourModal(true);
      setDate(clickInfo.dateStr);
    } else {
      alertHelper.info({ message: "Operaci nelze provést. Vyberte jen jednu masérnu a zkuste to znovu.", duration: 3000 });
    }
  }

  const [openHourModal, setOpenHourModal] = useState(false);
  const [openDailyNote, setOpenDailyNote] = useState(false);

  function handleEventClick(clickInfo) {
    if (!clickInfo.allDay && !clickInfo.event.allDay) {
      dispatch(fetchEventByAsync(clickInfo.event.id));
      setSelected(clickInfo.event.id);
      setOpenHourModal(true);
    } else if (clickInfo.event.allDay) {
      dispatch(fetchDailyNoteBy(clickInfo.event.id));
      setSelected(clickInfo.event.id);
      setOpenDailyNote(true);
    }
  }

  const headerToolbarConf = {
    left: "title",
    center: "dayGridMonth timeGridWeek",
    right: "search addDailyNote today prev,next"
  };
  const [headerToolbar, setHeaderToolbar] = useState(headerToolbarConf);

  const eventViewConstants = { CALENDAR_VIEW: 0, AGENDA_VIEW: 1 };
  const [eventView, setEventView] = useState(eventViewConstants.CALENDAR_VIEW);

  function onClickSearch()
  {
    setEventView(eventViewConstants.AGENDA_VIEW);
  }

  return (
    <div className={classes.root}>
      <SearchForm
        headerToolbarConf={headerToolbarConf}
        headerToolbar={headerToolbar}
        setHeaderToolbar={setHeaderToolbar}
        setEventView={setEventView}
        eventViewConstants={eventViewConstants}
        isAgendaView={eventView === eventViewConstants.AGENDA_VIEW}
      />
      <Agenda
        className={eventView === eventViewConstants.AGENDA_VIEW ? "" : classes.dNone}
        handleEventClick={handleEventClick}
        isAgendaView={eventView === eventViewConstants.AGENDA_VIEW}
      />
      <span style={getVisibility(eventView, eventViewConstants)}>
        <FullCalendar
          plugins={[timeGridPlugin, dayGridMonth, interactionPlugin, bootstrapPlugin]}
          customButtons={{
            addDailyNote: {
              text: "Přidat denní pozn.",
              click: () => setOpenDailyNote(true)
            },
            search: {
              text: "Hledání",
              bootstrapFontAwesome: "search",
              click: () => onClickSearch()
            }
          }}
          headerToolbar={headerToolbar}
          themeSystem="bootstrap"
          initialView="timeGridWeek"
          slotMinTime="08:00"
          slotMaxTime="23:00"
          expandRows={true}
          locale={csLocale}
          events={calendarEvents}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          nowIndicator={true}
        />
      </span>
      <AllDayModal date={date} open={openAllDayModal} setOpen={setOpenAllDayModal} />
      <HourModal open={openHourModal} setOpen={setOpenHourModal} selected={selected} setSelected={setSelected} />
      <AddHourModal open={openAddHourModal} setOpen={setAddOpenHourModal} date={date}  />
      <DailyNoteDialog
        openDailyNote={openDailyNote}
        setOpenDailyNote={setOpenDailyNote}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};

function getVisibility(eventView, eventViewConstants)
{
  var display = 'none';
  if (eventView === eventViewConstants.CALENDAR_VIEW) {
    display = 'inline';
  }

  return  {display: display}
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important"
  },

  serachInput: { marginBottom: theme.spacing(2) }
}));

export default Calendar;
