import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";
import EnhancedTableToolbar from "../enhanced/EnhancedTableToolbar";
import {exportCompanyReceptionistsCash, exportSummarizationValues} from "../../features/advancedReportSlice";
import EnhancedDateField from "../enhanced/EnhancedDateField";
import EnhancedSelect from "../enhanced/EnhancedSelect";
import {fetchCompanyList} from "../../features/listsSlice";
import alertHelper from "../../helpers/alertHelper";
import {getDBDateFromMomentObject} from "../../helpers/DateConvertor"

function AdvancedReportForm() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const allowedCompanyList = useSelector((state) => state.lists.companyList);

  useEffect(() => {
    dispatch(fetchCompanyList());
  }, [dispatch]);

  const [formState, setFormState] = useState({});
  let scenarioList = [
    { id: "card-slevomat", name: "Report tržeb kartou a slevomatů" },
    { id: "receptionists-company", name: "Výkaz hotovostních tržeb za pobočku a recepční" },
  ];

  const handleChangeDateSince = (event) => {
    setFormState((formState) => ({
      ...formState,
      ['dateSince']: event
    }));
  };

  const handleChangeDateTo = (event) => {
    setFormState((formState) => ({
      ...formState,
      ['dateTo']: event
    }));
  };

  function handleChange(event) {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  }

  const handleGenerate = () => {
    if (typeof formState.dateSince !== "object") {
      alertHelper.error({ message: "Datum od je v nevalidním stavu", duration: 3000 });
      return false;
    }

    if (typeof formState.dateTo !== "object") {
      alertHelper.error({ message: "Datum do je v nevalidním stavu", duration: 3000 });
      return false;
    }

    const apiData = {
      dateSince: getDBDateFromMomentObject(formState.dateSince),
      dateTo: getDBDateFromMomentObject(formState.dateTo)
    };

    if (formState.scenario === "card-slevomat") {
      dispatch(exportSummarizationValues(apiData));
    } else if (formState.scenario === "receptionists-company") {
      apiData.companyId = formState.companyId;
      dispatch(exportCompanyReceptionistsCash(apiData));
    }
  };

  return (
   <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar label="Sumarizační reporty" />
        <Grid container spacing={1}>
          <Grid item xs={6} className={classes.formControl}>
            <EnhancedDateField
                name="dateSince"
                value={formState.dateSince}
                onChange={handleChangeDateSince}
                label="Datum od"
            />
          </Grid>
          <Grid item xs={6} className={classes.formControl}>
            <EnhancedDateField
                name="dateTo"
                value={formState.dateTo}
                onChange={handleChangeDateTo}
                label="Datum do"
            />
          </Grid>
          <Grid item xs={12} className={classes.formControl}>
            <EnhancedSelect
                name="scenario"
                value={formState.scenario}
                label="Scénář"
                list={scenarioList}
                onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} className={classes.formControl}>
            {formState.scenario === "receptionists-company" && allowedCompanyList.length > 0 ? (
                <EnhancedSelect
                    name="companyId"
                    value={formState.companyId}
                    label="Organizace"
                    list={allowedCompanyList}
                    itemId="id"
                    itemName="name"
                    onChange={handleChange}
                />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <EnhancedConfirmButton handleOk={handleGenerate} okLabel="Vygenerovat report" />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  }
}));

export default AdvancedReportForm;
