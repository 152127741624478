import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EnhancedSelect from "../enhanced/EnhancedSelect";
import EnhancedTextField from "../enhanced/EnhancedTextField";
import { fetchMasseurs, fetchReceptionists } from "../../features/listsSlice";
import { createAllDayEventAsync } from "../../features/reservationSlice";
import { getSessionCheckedCompany, getSessionCheckedRooms } from "../../helpers/sessionHelper";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";

function AllDayModal(props) {
  const dispatch = useDispatch();
  const receptionistList = useSelector((state) => state.lists.receptionistList);
  const masseurList = useSelector((state) => state.lists.masseurList);

  const { open, date, setOpen } = props;

  const [formState, setFormState] = useState({});

  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      startAtWork: masseurList?.find((m) => m.id === +formState.masseur)?.startAtWork || "07:30"
    }));
  }, [formState.masseur, masseurList]);

  useEffect(() => {
    if (open) {
      dispatch(fetchMasseurs());
      dispatch(fetchReceptionists());
    }
  }, [dispatch, open]);

  const handleOk = () => {
    const apiData = {};
    apiData.masseurId = +formState.masseur;
    apiData.receptionistId = +formState.receptionist;
    apiData.roomId = getSessionCheckedRooms().map((c) => c[0])[0];
    apiData.startAtWork = formState.startAtWork;
    apiData.date = date;

    dispatch(createAllDayEventAsync(apiData));
    handleClose();
  };

  const handleClose = () => {
    setFormState({});
    setOpen(false);
  };

  const handleChange = (e) => {
    e.persist();
    setFormState((formState) => ({
      ...formState,
      [e.target.name]: e.target.value
    }));
  };

  const isOkDisabled = () => !formState.receptionist || !formState.masseur;

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Naplánování celého dne" />
        <DialogContent>
          <Grid container spacing={3} sx={{marginTop: -2.3}}>
            <Grid item xs={4}>
              <EnhancedSelect
                name="receptionist"
                value={formState.receptionist}
                label="Recepční"
                list={receptionistList}
                itemName="fullName"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedSelect
                name="masseur"
                value={formState.masseur}
                label="Masér"
                list={masseurList}
                itemName="fullName"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedTextField
                name="startAtWork"
                value={formState.startAtWork}
                label="Začátek práce"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleClose={handleClose} handleOk={handleOk} disabledOk={isOkDisabled()} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AllDayModal;
