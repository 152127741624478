import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { editPassLog } from "../../features/passLogSlice";
import { exportBillPdf } from "../../features/paymentSlice";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";
import EnhancedTextField from "../enhanced/EnhancedTextField";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";
import GrantedAuthHelper from "../../helpers/GrantedAuthHelper";
import EnhancedDateField from "../enhanced/EnhancedDateField";
import {getDBDateFromMomentObject} from "../../helpers/DateConvertor";
import Moment from "moment";
import { buttonStyles } from "../buttonColors";

function PassLogEditDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {open, setOpen, passlog} = props;
  const [formState, setFormState] = useState({});

  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      title: passlog?.title,
      number: passlog?.number,
      numberVisible: passlog?.numberVisible,
      dateExpire: new Moment(passlog?.dateExpire),
      billUUID: passlog?.billUUID,
      note: passlog?.note,
      showExportBillButton: passlog?.showExportBillButton
    }));
  }, [passlog]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleChangeDateExpire = (event) => {
    setFormState((formState) => ({
      ...formState,
      ['dateExpire']: event
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    const apiData = {
      id: passlog.id,
      title: formState.title,
      voucherNumber: formState.number,
      dateExpire: getDBDateFromMomentObject(formState.dateExpire),
      note: formState.note
    };

    dispatch(editPassLog(apiData));
    handleClose();
  };

  function handleExportBill() {
    if (formState.billUUID) {
      dispatch(exportBillPdf(formState.billUUID));
    }
  }

  function isTitleDisabled() {
    return !GrantedAuthHelper.hasAuthority("passlog.all");
  }

  function isExportBillDisabled() {
    return !formState.showExportBillButton;
  }

  function isExpiredDisabled() {
    return !GrantedAuthHelper.hasAuthority("passlog.all");
  }

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Úprava permanentky" />
        <DialogContent>
          <Grid container spacing={3} sx={{marginTop: -2.3}}>
            <Grid item xs={12}>
              <EnhancedTextField
                name="title"
                value={formState.title}
                label="Jméno"
                onChange={handleChange}
                disabled={isTitleDisabled()}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                name="numberVisible"
                value={formState.numberVisible}
                label="Číslo poukazu"
                onChange={handleChange}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedDateField
                  name="dateExpire"
                  label="Platnost do"
                  value={formState.dateExpire}
                  handleChange={handleChangeDateExpire}
                  disabled={isExpiredDisabled()}
              />
            </Grid>
            <Grid item xs={12}>
              <EnhancedTextField name="note" value={formState.note} label="Poznámka" onChange={handleChange} rows={5} multiline={true} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleOk={handleOk} okLabel="Uložit" />
          <Button
              onClick={handleExportBill}
              color="secondary"
              variant="contained"
              sx={buttonStyles.exportBill}
              disabled={isExportBillDisabled()}
          >
            Účtenka
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default PassLogEditDialog;
