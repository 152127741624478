import { Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import { makeStyles } from '@mui/styles';
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { insertVoucher } from "../../features/voucherSlice";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";
import EnhancedTextField from "../enhanced/EnhancedTextField";
import {fetchVoucherPrices} from "../../features/paymentSlice";
import alertHelper from "../../helpers/alertHelper";
import PaymentTypeResolver from "../../helpers/paymentTypeResolver";
import { getSessionCheckedCompany } from "../../helpers/sessionHelper";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";
import EnhancedTicketPayment from "../enhanced/EnhancedTicketPayment";
import EnhancedDateField from "../enhanced/EnhancedDateField";
import {getDBDateFromMomentObject} from "../../helpers/DateConvertor";

function NewVoucherDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { open, setOpenNew } = props;

  const [formState, setFormState] = useState({});
  const [startDate] = useState(new moment().add(6, "months"));
  const typePriceOfMassageList = useSelector((state) => state.payment.typePriceOfMassageList);

  useEffect(() => {
    const { paymentTypeId } = formState;
    if (open) {
      paymentTypeId && dispatch(fetchVoucherPrices(paymentTypeId));
    }
  }, [open, formState, dispatch]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleChangeDateExpire = (event) => {
    setFormState((formState) => ({
      ...formState,
      ['dateExpire']: event
    }));
  };

  const handleClose = () => {
    setOpenNew(false);
    setFormState({});
  };

  const handlePayAndSave = () => {
    if (!formState.title) {
      alertHelper.error({ message: "Musíte zadat titulek dárkového poukazu!" });
      return false;
    }

    if (!formState.paymentTypeId) {
      alertHelper.error({ message: "Musíte zadat typ platby!" });
      return false;
    }

    if (PaymentTypeResolver.isBill(formState.paymentTypeId) && !formState.billNumber) {
      alertHelper.error({ message: "Musíte zadat číslo faktury!" });
      return false;
    }

    if (!formState.typeOfMassageId) {
      alertHelper.error({ message: "Musíte zadat typ masáže!" });
      return false;
    }

    const apiData = {};
    apiData.companyId = getSessionCheckedCompany();
    apiData.title = formState.title;
    apiData.dateExpire = getDBDateFromMomentObject(formState.dateExpire || startDate);
    apiData.paymentTypeId = formState.paymentTypeId;
    apiData.createNumber = formState.createNumber || 1;
    apiData.massageTypeId = formState.typeOfMassageId;
    apiData.billUUID = formState.billNumber;
    apiData.invoiceAddress = formState.invoiceAddress;

    alertHelper.confirm({
      message: "Opravdu si přejete uložit a zaplatit dárkový poukaz?",
      callback: () => dispatch(insertVoucher(apiData))
    });

    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Nový dárkový poukaz" />
        <DialogContent>
          <Grid container spacing={3} sx={{marginTop: -2.3}}>
            <Grid item xs={12}>
              <EnhancedTextField name="title" value={formState.title} label="Jméno" onChange={handleChange} />
            </Grid>
            <Grid item xs={6}>
              <EnhancedDateField
                  name="dateExpire"
                  label="Platnost do"
                  value={formState.dateExpire || startDate}
                  handleChange={handleChangeDateExpire}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                  name="createNumber"
                  value={formState.createNumber}
                  label="Počet vytvoření"
                  onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <EnhancedTicketPayment
                formState={formState}
                open={open}
                handleChange={handleChange}
                disabled={false}
                typePriceOfMassageList={typePriceOfMassageList}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleClose={handleClose} handleOk={handlePayAndSave} okLabel="Uložit a zaplatit" />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default NewVoucherDialog;
