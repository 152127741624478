import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getSessionCheckedCompany, getSessionCheckedRooms} from "../../../helpers/sessionHelper";
import {fetchMasseurs, fetchReceptionists, fetchRooms} from "../../../features/listsSlice";
import {addHourEventAsync} from "../../../features/reservationSlice";
import EnhancedDialogTitle from "../../enhanced/EnhancedDialogTitle";
import EnhancedTextField from "../../enhanced/EnhancedTextField";
import EnhancedSelect from "../../enhanced/EnhancedSelect";
import HMCustomerSection from "./HMCustomerSection";
import HMStartEndSection from "./HMStartEndSection";
import {reservationValidator} from "../../../helpers/validator/reservationValidator";
import EnhancedConfirmButton from "../../enhanced/EnhancedConfirmButton";

function AddHourModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const masseurList = useSelector((state) => state.lists.masseurList);
  const receptionistList = useSelector((state) => state.lists.receptionistList);
  const roomList = useSelector((state) => state.lists.roomList);


  const { open, setOpen, date } = props;
  const [formState, setFormState] = useState({});
  const [startAtWork, setStart] = useState();
  const [endAtWork, setEnd] = useState();

  useEffect(() => {
    if (open && getSessionCheckedRooms().length === 1) {
      setFormState((formState) => ({
        ...formState,
        room: getSessionCheckedRooms().map((c) => c[0])[0]
      }));
    }
  }, [setFormState, open]);

  useEffect(() => {
    if (getSessionCheckedCompany() && open) dispatch(fetchRooms(getSessionCheckedCompany()));
  }, [open, dispatch]);

  useEffect(() => {
    if (open) {
      dispatch(fetchMasseurs());
      dispatch(fetchReceptionists());
    }
  }, [open, dispatch]);

  function handleChange(event) {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  }

  function handleClose() {
    formState.title = "";
    formState.note = "";
    formState.masseurId = null;
    formState.receptionistId = null;
    formState.customerId = null;
    formState.room = null;
    setOpen(false);
  }

  function handleOk() {
    const apiData = {};

    if (!reservationValidator(formState, startAtWork, endAtWork)) {
      return false;
    }

    apiData.title = formState.title;
    apiData.start = startAtWork;
    apiData.end = endAtWork;
    apiData.note = formState.note;
    apiData.masseurId = formState.masseurId;
    apiData.receptionistId = formState.receptionistId;
    apiData.customerId = formState.customer?.id;
    apiData.roomId = formState.room;

    handleClose();
    dispatch(addHourEventAsync(apiData));
  }

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" sx={{zIndex: 2}}>
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Vytvořit rezervaci" />
        <DialogContent>
          <Grid container spacing={3} sx={{marginTop: -2.3}}>
            <Grid item xs={12}>
              <EnhancedTextField
                name="title"
                value={formState.title || ''}
                label="Název"
                onChange={handleChange}
              />
            </Grid>
            <HMCustomerSection
              formState={formState}
              setFormState={setFormState}
              handleChange={handleChange}
            />
            <Grid item xs={4}>
              <EnhancedSelect
                  disabled={true}
                  native
                  value={formState.room || ""}
                  label="Masérna"
                  onChange={handleChange}
                  name="room"
                  list={roomList}
                  itemName="name"
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedSelect
                  name="receptionistId"
                  value={formState.receptionistId}
                  label="Recepční"
                  list={receptionistList}
                  onChange={handleChange}
                  itemName="fullName"
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedSelect
                  name="masseurId"
                  value={formState.masseurId}
                  label="Masér"
                  list={masseurList}
                  onChange={handleChange}
                  itemName="fullName"
              />
            </Grid>
            <HMStartEndSection
              formState={formState}
              setFormState={setFormState}
              handleChange={handleChange}
              setStart={setStart}
              setEnd={setEnd}
              open={open}
              date={date}
            />
            <Grid item xs={12}>
              <EnhancedTextField name="note" value={formState.note} label="Poznámka" onChange={handleChange} rows={5} multiline={true} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleOk={handleOk} okLabel="Potvrdit" />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    width: "100%"
  }
}));

export default AddHourModal;
