import React from "react";
import { Avatar, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Profile = () => {
  const userProfile = useSelector((state) => state.auth.main);
  const classes = useStyles();

  function getInicials(text) {
    return text
      ?.split(" ")
      .map((i) => i[0])
      .join("");
  }

  return (
    <div className={classes.root}>
      <Avatar alt="Person" className={classes.avatar} component={RouterLink} to="/profile">
        {getInicials(userProfile.name)}
      </Avatar>
      <Typography className={classes.name} variant="h5">
        {userProfile.name}
      </Typography>
      <Typography variant="body2">{userProfile.role}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content"
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1),
    textAlign: "center",
  }
}));

export default Profile;
