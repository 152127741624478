import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";
import "date-fns";
import {editUserAttendanceList} from "../../features/attendanceListSlice";
import Moment from "moment";
import {TextField} from "@mui/material";

function AttendanceListEditDialog(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {open, setOpen, attendanceList} = props;
    const [formState, setFormState] = useState({});
    const [arrival, setArrival] = useState();
    const [departure, setDeparture] = useState();

    useEffect(() => {
        if (open) {
            setFormState((formState) => ({
                ...formState,
                arrival: formatTime(attendanceList?.arrival),
                departure: formatTime(attendanceList?.departure)
            }));

            distributeValue(attendanceList?.arrival, attendanceList?.departure);
        }
    }, [open, attendanceList, setFormState]);

    function setArrivaldepartureAfterChange(date) {
        let dateStr = new Moment(date);
        let dateStrHr = new Moment(date);
        dateStrHr.set({
            hour: dateStr.hour() + 8
        });
        setFormState((formState) => ({
            ...formState,
            arrival: dateStr,
            departure: dateStrHr
        }));

        distributeValue(dateStr, dateStrHr);
    }

    function formatTime(dateTime) {
        if (!dateTime) {
            return "";
        }
        return Moment(dateTime);
    }

    function formatDateTime(dateTime) {
        if (!dateTime) {
            return "";
        }

        return Moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    }

    function handleBlur(e) {
        distributeValue(formState.arrival, formState.departure);
    }

    function handleChangeStartDate(date) {
        setArrivaldepartureAfterChange(date);
    }

    function handleChangeEndDate(date) {
        setFormState((formState) => ({
            ...formState,
            departure: date
        }));

        distributeValue(formState.arrival, formState.departure);
    }

    function distributeValue(start, end) {
        let arrival;
        let departure;

        arrival = formatDateTime(start);
        departure = formatDateTime(end);

        setArrival(arrival);
        setDeparture(departure);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        const apiData = {
            attendanceId: attendanceList.id,
            arrival: arrival,
            departure: departure
        };

        dispatch(editUserAttendanceList(apiData));
        handleClose();
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
                <EnhancedDialogTitle handleClose={handleClose} nameLabel="Úprava docházky" />
                <DialogContent>
                    <Grid container spacing={3} sx={{marginTop: -2.3}}>
                        <Grid item xs={6}>
                            <TextField
                                name="arrival"
                                value={formState.arrival}
                                label="Příchod"
                                inputVariant="outlined"
                                format="dd.MM. yyyy HH:mm"
                                autoOk
                                ampm={false}
                                className={classes.textField}
                                onChange={handleChangeStartDate}
                                onBlur={handleBlur}
                                type="datetime-local"
                                defaultValue="2017-05-24T10:30"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="departure"
                                value={formState.departure}
                                label="Odchod"
                                inputVariant="outlined"
                                format="dd.MM. yyyy HH:mm"
                                ampm={false}
                                className={classes.textField}
                                onChange={handleChangeEndDate}
                                onBlur={handleBlur}
                                type="datetime-local"
                                defaultValue="2017-05-24T10:30"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <EnhancedConfirmButton handleOk={handleOk} okLabel="Uložit" />
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    textField: {
        width: 450
    }
}));

export default AttendanceListEditDialog;
