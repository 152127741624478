import Swal from "sweetalert2";
class AlertHelper {
  constructor() {
    this.alerter = null;
    this.confirmColor = '#8bc34a';
  }

  registerAlert(alerter) {
    this.alerter = alerter;
  }

  confirm({ message, callback, className = null }) {
    if (this.alerter !== null) {
      Swal.fire({
        title: message,
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "Ano",
        cancelButtonText: "Zavřít",
        confirmButtonColor: this.confirmColor,
        cancelButtonColor: "#d33",
        customClass: {
          cancelButton: 'order-1 right-gap',
          confirmButton: 'order-2',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          callback();
          Swal.fire({
            title: 'Hotovo',
            icon: "success",
            confirmButtonColor: this.confirmColor,
          });
        }
      });
    }
  }

  info({ message, duration = 7000 }) {
    let timerInterval;
    Swal.fire({
      title: "Upozornění",
      html: message+"<br><br>Okno se zavře za <b></b> milisekund.",
      timer: duration,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const timer = Swal.getPopup().querySelector("b");
        timerInterval = setInterval(() => {
          timer.textContent = `${Swal.getTimerLeft()}`;
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      }
    });
  }

  error({ message, duration = 7000 }) {
    if (this.alerter !== null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message,
        confirmButtonColor: "#8bc34a",
      });
      //this.alerter.alert(message, duration, "error");
    }
  }

  warning({ message, duration = 7000 }) {
    if (this.alerter !== null) {
      this.alerter.alert(message, duration, "warning");
    }
  }

  success({ message, duration = 7000 }) {
    if (this.alerter !== null) {
      this.alerter.alert(message, duration, "success");
    }
  }
}

export default new AlertHelper();
