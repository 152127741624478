import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  getSessionCalendarSettings,
  getSessionCheckedCompany,
  getSessionCheckedRooms,
  getSessionCompanyId,
  getSessionRooms
} from "../helpers/sessionHelper";
import {getFetchURL} from "../helpers/actionHelpers";

export const [IDLE, SUCCESS, LOADING, ERROR] = ["idle", "success", "loading", "error"];

const initialState = {
  calendar: [],
  searchResults: [],
  dataById: {},
  status: IDLE,
  notPaidReservationList: []
};

export const createAllDayEventAsync = createAsyncThunk(
  "reservation/createAllDayEventAsync",
  async (apiData, { dispatch }) => {
    const options = {
      method: "PUT",
      data: apiData,
      headers: { "Content-Type": "application/json" },
      url: getFetchURL("/reservation-calendar/place")
    };

    const response = await axios(options).then((_) => {
      dispatch(fetchEventsAsync());
    });

    return response.data;
  }
);

export const editHourEventAsync = createAsyncThunk("reservation/editHourEvent", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/reservation/edit")
  };

  await axios(options).then((response) => {
    dispatch(fetchEventsAsync());
    dispatch(fetchNotPaidEventByCompany());
    return response;
  });
});

export const addHourEventAsync = createAsyncThunk("reservation/addHourEvent", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/reservation/add")
  };

  const response = await axios(options).then((response) => {
    dispatch(fetchEventsAsync());
  });

  return response.data;
});

export const customerIsNotComeAsync = createAsyncThunk("reservation/customerIsNotCome", async (id, { dispatch }) => {
  const options = {
    method: "POST",
    data: { id: id },
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/reservation/not-come")
  };

  const response = await axios(options).then((response) => {
    dispatch(fetchNotPaidEventByCompany());
    dispatch(fetchEventsAsync());
    return response;
  });

  return response.data;
});

export const deleteEventByAsync = createAsyncThunk("calendar/deleteEventBy", async (id, { dispatch }) => {
  const options = {
    method: "delete",
    headers: { "Content-Type": "application/json" },
    url: getFetchURL(`/reservation/delete/${id}`)
  };

  const response = await axios(options).then((response) => {
    dispatch(fetchEventsAsync());
  });

  return response.data;
});

export const fetchEventByAsync = createAsyncThunk("reservation/fetchEventByAsync", async (id, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/reservation/get/${id}`));

  return response.data;
});

export const fetchEventsAsync = createAsyncThunk("reservation/fetchEventsAsync", async (_, { dispatch }) => {
  const options = {
    method: "post",
    data: { roomIds: getSessionCheckedRooms().map((c) => c[0]) },
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/reservation-calendar/room")
  };

  let response = {};

  if (Object.keys(getSessionRooms()).length !== 0) {
    response = await axios(options);
  }

  return response.data;
});

export const fetchCustomerEventsAsync = createAsyncThunk("reservation/fetchCustomerEventsAsync", async (_, { dispatch }) => {
  const options = {
    method: "get",
    data: { roomIds: getSessionCheckedRooms().map((c) => c[0]) },
    headers: { "Content-Type": "application/json" },
    url: getFetchURL(`/customer/reservations/${getSessionCompanyId()}`)
  };

  let response = {};

  if (getSessionCompanyId()) {
    response = await axios(options);
  }

  return response.data;
});

export const findReservationEvent = createAsyncThunk("reservation/findReservationEvent", async (text, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/reservation-calendar/find/${getSessionCheckedCompany()}/${text}`));

  return response.data;
});

export const fetchMasseursEvents = createAsyncThunk("reservation/fetchMasseursEvents", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/agenda/masseur/${getSessionCheckedCompany()}`));

  return response.data;
});

export const fetchCleanerEvents = createAsyncThunk("reservation/fetchCleanerEvents", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/agenda/cleaner/${getSessionCheckedCompany()}`));

  return response.data;
});

export const cancelPayment = createAsyncThunk("reservation/cancelPayment", async (apiData, { dispatch }) => {
  const options = {
    method: "POST",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/payment/cancel")
  };

  const response = await axios(options).then((response) => {
    dispatch(fetchEventsAsync());

    return response;
  });
  return response.data;
});

export const fetchNotPaidEventByCompany = createAsyncThunk("reservation/fetchNotPaidEventByCompany", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/reservation-calendar/not-paid/get/${getSessionCompanyId()}`));
  return response.data;
});

export const reservationSlice = createSlice({
  name: "reservation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(customerIsNotComeAsync.fulfilled, (state, action) => {
        const id = action.meta.arg;
        state.dataById[id] = action.payload;
      })
      .addCase(fetchEventByAsync.pending, (state, action) => {
        state.status = LOADING;
      })
      .addCase(fetchEventByAsync.fulfilled, (state, action) => {
        const id = action.meta.arg;
        state.dataById[id] = action.payload;
        state.status = SUCCESS;
      })
      .addCase(fetchEventByAsync.rejected, (state, action) => {
        state.status = ERROR;
      })
      .addCase(fetchEventsAsync.fulfilled, (state, action) => {
        state.calendar = action.payload;
      })
      .addCase(fetchCustomerEventsAsync.fulfilled, (state, action) => {
        state.calendar = action.payload;
      })
      .addCase(findReservationEvent.fulfilled, (state, action) => {
        state.searchResults = action.payload;
      })
      .addCase(fetchMasseursEvents.fulfilled, (state, action) => {
        state.calendar = action.payload;
      })
      .addCase(fetchCleanerEvents.fulfilled, (state, action) => {
        state.calendar = action.payload;
      })
      .addCase(cancelPayment.fulfilled, (state, action) => {
        const id = action.meta.arg.reservationId;
        state.dataById[id] = action.payload;
      })
      .addCase(fetchNotPaidEventByCompany.fulfilled, (state, action) => {
        state.notPaidReservationList = action.payload;
      })
  }
});

export default reservationSlice.reducer;
