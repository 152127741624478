import React, {useEffect, useState} from "react";
import {Grid, Paper} from "@mui/material";
import { makeStyles } from '@mui/styles';
import {useDispatch, useSelector} from "react-redux";
import {changePassword, fetchTokenInfo} from "../../features/customerSlice";
import EnhancedTextField from "../enhanced/EnhancedTextField";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";
import PasswordChecklist from "react-password-checklist"
import alertHelper from "../../helpers/alertHelper";
import {useHistory} from "react-router-dom";

function ChangePassword(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const tokenInfo = useSelector((state) => state.customerSlice.tokenInfo);
    const changePasswordRes = useSelector((state) => state.customerSlice.changePasswordRes);
    const [isPasswordValid, setPasswordValid] = useState(true);
    const [isRedirectToLoginPage, setRedirectToLoginPage] = useState(false);
    const [formState, setFormState] = useState({});
    useEffect(() => {
        setFormState((formState) => ({
            ...formState,
            expiredAt: tokenInfo.expiredAt,
            isExpired: tokenInfo.isExpired,
            showForm: tokenInfo.isExpired === false,
            password: '',
            passwordConfirm: ''
        }));
    }, [tokenInfo]);

    useEffect(() => {
        if (props.match.params.token) {
            dispatch(fetchTokenInfo(props.match.params.token));
        }
    }, [dispatch]);

    useEffect(() => {
        if (isRedirectToLoginPage && changePasswordRes[0] === "OK") {
            alertHelper.info({
                message: "Heslo bylo změněno. Nyní se můžete přihlásit.",
                duration: 2000
            });
            history.push('/sign-in');
        }
    }, [isRedirectToLoginPage, changePasswordRes]);

    function handleChange(event) {
        event.persist();
        setFormState((formState) => ({
            ...formState,
            [event.target.name]: event.target.value
        }));
    }

    const isDisable = () => {
        return !isPasswordValid;
    }
    const handleChangePassword = () => {
        if (formState.password === '' && formState.passwordConfirm === '') {
            alertHelper.error({ message: "Hesla jsou prázdná!" });
            return false;
        }

        if (!isPasswordValid) {
            alertHelper.error({ message: "Hesla nejsou validní!" });
            return false;
        }

        var apiData = {
            'token': props.match.params.token,
            'password': formState.password,
            'confirmPassword': formState.passwordConfirm,
        }

        dispatch(changePassword(apiData));
        setRedirectToLoginPage(true);
    }

  return (
    <div>
      <Paper>
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ marginTop: 0, padding: 5 }}
        >
            <div>
                <h2>Nastavte si nové heslo</h2>
                {formState.showForm ? (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <PasswordChecklist
                                rules={["notEmpty","minLength","specialChar","number","capital","match"]}
                                minLength={8}
                                value={formState.password || ''}
                                valueAgain={formState.passwordConfirm || ''}
                                messages={{
                                    notEmpty: "Hesla jsou vyplněná.",
                                    minLength: "Heslo má alespoň 8 znaků.",
                                    specialChar: "Heslo obsahuje speciální znaky.",
                                    number: "Heslo obsahuje číslici.",
                                    capital: "Heslo obsahuje velké písmeno.",
                                    match: "Hesla se shodují.",
                                }}
                                onChange={(isValid) => {
                                    setPasswordValid(isValid);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EnhancedTextField
                                name="password"
                                value={formState.password}
                                label="Heslo"
                                onChange={handleChange}
                                maxLength="90"
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EnhancedTextField
                                name="passwordConfirm"
                                value={formState.passwordConfirm}
                                label="Heslo pro potvrzení"
                                onChange={handleChange}
                                maxLength="90"
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EnhancedConfirmButton
                                handleOk={handleChangePassword}
                                okLabel="Změnit heslo"
                                disabledOk={isDisable()}
                            />
                        </Grid>
                    </Grid>
                ) :
                    <p>Vaše heslo nelze změnit. Platnost tokenu pro změnu hesla vypršela nebo token nebyl nalezen.</p>
                }

            </div>
        </Grid>
      </Paper>
    </div>
  );
}

export default ChangePassword;
