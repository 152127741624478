import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import "@fortawesome/fontawesome-free/css/all.css";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import csLocale from "@fullcalendar/core/locales/cs";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridMonth from "@fullcalendar/daygrid"
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from "react-redux";
import {fetchCustomerEventsAsync, fetchEventByAsync} from "../../features/reservationSlice";
import {getSessionCompanyId} from "../../helpers/sessionHelper";
import "src/styles/fullcalendar-vars.css";
import HourModal from "./HourModal";

const CustomerCalendar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const calendar = useSelector((state) => state.reservation.calendar);

  const [date, setDate] = useState(false);
  const [selected, setSelected] = useState(null);
  const [calendarEvents, setCalendarEvents] = useState(calendar);
  useEffect(() => {
    setCalendarEvents(calendar);
  }, [calendar]);

  useEffect(() => {
    if (getSessionCompanyId()) {
      dispatch(fetchCustomerEventsAsync())
    }

  }, [dispatch]);


  const [openHourModal, setOpenHourModal] = useState(false);

  function handleEventClick(clickInfo) {
    if (clickInfo.event._def.extendedProps.clickable
        && !clickInfo.allDay
        && !clickInfo.event.allDay
    ) {
      dispatch(fetchEventByAsync(clickInfo.event.id));
      setSelected(clickInfo.event.id);
      setOpenHourModal(true);
    }
  }

  const headerToolbarConf = {
    left: "title",
    center: "dayGridMonth timeGridWeek",
    right: "today prev,next"
  };
  const [headerToolbar, setHeaderToolbar] = useState(headerToolbarConf);

  const eventViewConstants = { CALENDAR_VIEW: 0, AGENDA_VIEW: 1 };
  const [eventView, setEventView] = useState(eventViewConstants.CALENDAR_VIEW);

  return (
    <div className={classes.root}>
      <span style={getVisibility(eventView, eventViewConstants)}>
        <FullCalendar
          plugins={[timeGridPlugin, dayGridMonth, interactionPlugin, bootstrapPlugin]}
          headerToolbar={headerToolbar}
          themeSystem="bootstrap"
          initialView="timeGridWeek"
          slotMinTime="08:00"
          slotMaxTime="20:00"
          locale={csLocale}
          expandRows={true}
          events={calendarEvents}
          eventClick={handleEventClick}
          nowIndicator={true}
        />
      </span>
      <HourModal open={openHourModal} setOpen={setOpenHourModal} selected={selected} setSelected={setSelected} />
    </div>
  );
};

function getVisibility(eventView, eventViewConstants)
{
  var display = 'none';
  if (eventView === eventViewConstants.CALENDAR_VIEW) {
    display = 'inline';
  }

  return  {display: display}
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important"
  },
}));

export default CustomerCalendar;
