import React from "react";
import Button from "@mui/material/Button";
import { buttonStyles } from "../buttonColors";

function EnhancedConfirmButton(props) {
  const { handleOk, okLabel = "Potvrdit", hideOk, disabledOk = false } = props;

  return (
    <>
      <Button
        onClick={handleOk}
        color="primary"
        variant="contained"
        disabled={disabledOk}
        sx={hideOk ? buttonStyles.dNone : buttonStyles.ok}
      >
        {okLabel}
      </Button>
    </>
  );
}

export default EnhancedConfirmButton;
