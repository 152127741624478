import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getFetchURL } from "../helpers/actionHelpers";
import {fetchCustomerEventsAsync} from "./reservationSlice";
const initialState = {
  tokenInfo: {},
  changePasswordRes: {}
};

export const signInToReservation = createAsyncThunk("customer/signInToReservation", async (apiData, { dispatch }) => {
  const options = {
    method: "POST",
    data: apiData,
    headers: {"Content-Type": "application/json"},
    url: getFetchURL("/customer/reservations/sign-in")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchCustomerEventsAsync());
  });

  return response.data;
});

export const signOutFromReservation = createAsyncThunk("customer/signOutFromReservation", async (apiData, { dispatch }) => {
  const options = {
    method: "POST",
    data: apiData,
    headers: {"Content-Type": "application/json"},
    url: getFetchURL("/customer/reservations/sign-out")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchCustomerEventsAsync());
  });

  return response.data;
});

export const fetchTokenInfo = createAsyncThunk("customer/fetchTokenInfo", async (token, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/check-token/${token}`));

  return response.data;
});

export const changePassword = createAsyncThunk("modalEdit/editCustomer", async (apiData, { dispatch }) => {
  const options = {
    method: "POST",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/password-change")
  };

  const response = await axios(options);

  return response.data;
});

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTokenInfo.fulfilled, (state, action) => {
      state.tokenInfo = action.payload;
    })
    .addCase(changePassword.fulfilled, (state, action) => {
      state.changePasswordRes = action.payload;
    });
  }
});

export default customerSlice.reducer;
