import {Grid} from "@mui/material";
import Paper from "@mui/material/Paper";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EnhancedTableToolbar from "../enhanced/EnhancedTableToolbar";
import {fetchUserProfileAsync} from "../../features/authSlice";

function Profile() {
  const dispatch = useDispatch();

  const profileInfo = useSelector((state) => state.auth.main);

  useEffect(() => {
    dispatch(fetchUserProfileAsync());
  }, [dispatch]);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar label="Můj profil" />
        <Grid container spacing={1}  style={{padding: 20}}>
          <Grid item xs={12}>
            Jméno: {profileInfo.name}
          </Grid>
          <Grid item xs={12}>
            E-Mail: {profileInfo.email}
          </Grid>
          <Grid item xs={12}>
            Role: {profileInfo.role}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
export default Profile;
