import React, { useEffect, useState } from "react";
import {CircularProgress} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import {makeStyles} from '@mui/styles';
import { useDispatch, useSelector } from "react-redux";
import EnhancedButton from "../enhanced/EnhancedButton";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";
import {
  IDLE,
  LOADING,
  SUCCESS
} from "../../features/reservationSlice";
import { buttonStyles } from "../buttonColors";
import {signInToReservation, signOutFromReservation} from "../../features/customerSlice";
import alertHelper from "../../helpers/alertHelper";

function HourModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const status = useSelector((state) => state.reservation.status);
  const dataById = useSelector((state) => state.reservation.dataById);

  const { open, setOpen, selected, setSelected } = props;

  const [formState, setFormState] = useState({});

  useEffect(() => {
    if (dataById[selected]) {
      setFormState((formState) => ({
        ...formState,
        id: dataById[selected]?.id,
        start: dataById[selected]?.start,
        customerName: dataById[selected]?.customer?.name,
        title: dataById[selected]?.title,
        length: dataById[selected]?.length,
        date: dataById[selected]?.date,
      }));
    }
  }, [dataById, selected]);


  function handleChange(event) {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  }

  function handleClose() {
    setSelected(null);
    setOpen(false);
    setFormState({});
  }

  function handleSignIn()
  {
    alertHelper.confirm({
      message: 'Opravdu se chcete přihlásit na tento termín masáže?',
      callback: () => callbackSignInOut(true),
      className: ''
    });
  }

  const handleSignOut = () =>{
    alertHelper.confirm({
      message: 'Opravdu se chcete odhlásit z této masáže?',
      callback: () => callbackSignInOut(false),
      className: ''
    });
  }

  function callbackSignInOut(signIn) {
    var apiData = {
      id: formState.id
    }

    if (signIn) {
      dispatch(signInToReservation(apiData));
    } else {
      dispatch(signOutFromReservation(apiData));
    }

    handleClose();
  }

  function isSignOutDisabled() {
    var cannotSignOut = false;
    if (formState.start) {
      var reservationStart = new Date(formState.start);
      var today = new Date();
      reservationStart.setHours(reservationStart.getHours() - 1);
      cannotSignOut = reservationStart < today;
    }

    return !formState.customerName || cannotSignOut;
  }

  function isSignInDisabled() {
    return  !!formState.customerName;
  }

  function getDialogContent() {
    if (status === LOADING) {
      return (
        <div style={{ height: "600px" }}>
          <CircularProgress className={classes.progress} />
        </div>
      );
    }

    if (status === IDLE) {
      return null;
    }

    if (status === SUCCESS)
      return (
        <Grid container spacing={3} sx={{marginTop: -2.3}}>
          <Grid item xs={12}>
            <div>
              <dt>Jméno</dt>
              <dd>{formState.customerName}</dd>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <dt>Datum a čas</dt>
              <dd>{formState.date}</dd>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <dt>Délka</dt>
              <dd>{formState.length} minut</dd>
            </div>
          </Grid>
        </Grid>
      );
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Rezervace" />
        <DialogContent>{getDialogContent()}</DialogContent>
        <DialogActions>
          <EnhancedButton
            onClick={handleSignOut}
            disabled={isSignOutDisabled()}
            label="Odhlásit se"
            authority="customer calendar.view"
            sx={buttonStyles.delete}
          />

          <EnhancedButton
            onClick={handleSignIn}
            disabled={isSignInDisabled()}
            label="Přihlásit se"
            authority="customer calendar.view"
            sx={buttonStyles.pay}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}



const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2)
  },
  root: {
    flexGrow: 1
  },
  formControl: {
    width: "100%"
  }
}));

export default HourModal;
