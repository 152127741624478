import { Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import { makeStyles } from '@mui/styles';
import Moment from "moment";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";
import EnhancedTextField from "../enhanced/EnhancedTextField";
import {fetchPassLogPrices} from "../../features/paymentSlice";
import { insertPassLog } from "../../features/passLogSlice";
import alertHelper from "../../helpers/alertHelper";
import PaymentTypeResolver from "../../helpers/paymentTypeResolver";
import { getSessionCheckedCompany } from "../../helpers/sessionHelper";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";
import EnhancedTicketPayment from "../enhanced/EnhancedTicketPayment";
import EnhancedDateField from "../enhanced/EnhancedDateField";
import {getDBDateFromMomentObject} from "../../helpers/DateConvertor";

function NewPassLogDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { open, setOpenNew } = props;
  const [formState, setFormState] = useState({});
  const [startDate] = useState(new Moment().add(12, "months"));
  const typePriceOfMassageList = useSelector((state) => state.payment.typePriceOfMassageList);

  useEffect(() => {
    const { paymentTypeId } = formState;
    if (open) {
      paymentTypeId && dispatch(fetchPassLogPrices(paymentTypeId));
    }
  }, [open, formState, dispatch]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleChangeDateExpire = (event) => {
    setFormState((formState) => ({
      ...formState,
      ['dateExpire']: event
    }));
  };

  const handleClose = () => {
    setOpenNew(false);
    setFormState({});
  };

  const handlePayAndSave = () => {
    if (!formState.title) {
      alertHelper.error({ message: "Musíte zadat titulek permanentky!" });
      return false;
    }

    if (!formState.paymentTypeId) {
      alertHelper.error({ message: "Musíte zadat typ platby!" });
      return false;
    }

    if (PaymentTypeResolver.isBill(formState.paymentTypeId) && !formState.billNumber) {
      alertHelper.error({ message: "Musíte zadat číslo faktury!" });
      return false;
    }

    if (!formState.typeOfMassageId) {
      alertHelper.error({ message: "Musíte zadat typ masáže!" });
      return false;
    }

    const apiData = {
      companyId: getSessionCheckedCompany(),
      title: formState.title,
      dateExpire: getDBDateFromMomentObject(formState.dateExpire || startDate),
      paymentTypeId: formState.paymentTypeId,
      massageTypeId: formState.typeOfMassageId,
      billUUID: formState.billNumber,
      note: formState.note,
      invoiceAddress: formState.invoiceAddress
    };

    alertHelper.confirm({
      message: "Opravdu si přejete uložit a zaplatit permanentku?",
      callback: () => dispatch(insertPassLog(apiData))
    });

    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Vložit novou permanentku" />
        <DialogContent>
          <Grid container spacing={3} sx={{marginTop: -2.3}}>
            <Grid item xs={6}>
              <EnhancedTextField name="title" value={formState.title} label="Jméno" onChange={handleChange} />
            </Grid>
            <Grid item xs={6}>
              <EnhancedDateField
                  name="dateExpire"
                  label="Platnost do"
                  value={formState.dateExpire || startDate}
                  handleChange={handleChangeDateExpire}
              />
            </Grid>
            <Grid item xs={12}>
              <EnhancedTextField name="note" value={formState.note} label="Poznámka" onChange={handleChange} rows={5} multiline={true} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <EnhancedTicketPayment
                formState={formState}
                open={open}
                handleChange={handleChange}
                disabled={false}
                typePriceOfMassageList={typePriceOfMassageList}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleOk={handlePayAndSave} okLabel="Uložit a zaplatit" />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default NewPassLogDialog;
