import React from "react";
import { Paper } from "@mui/material";
import { makeStyles } from '@mui/styles';

function SystemNews() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <h1>Novinky v aplikaci</h1>
        <div>#2024-09-25</div>
        <ul>
          <li>Seřazení uživatelů podle role a příznaku aktivní</li>
          <li>Upravené barvičky tlačítek</li>
          <li>Upraveny vyskakovací okna s chybami, upozorněním, potvrzovací okna</li>
          <li>Opraveno načtení recepčních a masérů při změně organizace</li>
          <li>Po přihlášení se vždy nezobrazuje výběr organizace a masérny - když je nastavená jen jedna organizace s jednou masérnou, tak se nezobrazuje</li>
        </ul>
        <hr/>
        <div>#2024-09-16</div>
        <ul>
        <li>Přidání nastavení k masérnám, aby se mohli vytvářet v nějakých masérnách i 30min masáže</li>
        </ul>
        <hr/>
        <div>#2024-09-04</div>
        <ul>
          <li>Přihlášení pomocí entru</li>
          <li>Seřazení seznamu uživatelů podle toho zda je aktivní či ne</li>
          <li>Kontrola pro smazaní již použité permanentky</li>
          <li>Smazání plateb při smazání permanentky, dárkového poukazu</li>
        </ul>
        <hr/>
        <div>#2024-03-23</div>
        <ul>
          <li>Drobné stylové úpravy</li>
        </ul>
        <hr/>
        <div>#2024-03-02</div>
        <ul>
          <li>Změna názvu masáže (permanentka, dp) v pokladním dokladu</li>
          <li>Oprava odhlášení, výpisu plateb</li>
        </ul>
        <hr/>
        <div>#2024-01-20</div>
        <ul>
          <li>Předělaná logika uplatnění poukazů - kvůli rychlosti (+ přidát sloupec applied v rezervacích)</li>
        </ul>
        <hr/>
        <div>#2023-12-31</div>
        <ul>
          <li>Přidány Informace o práv. vztahu založeném na HPP + administrace</li>
        </ul>
        <hr/>
        <div>#2023-08-08</div>
        <ul>
          <li>Aktualizace mui knihovny (knihovna, která obsahuje styly formulářů, formuláový elementy atd.)</li>
        </ul>
        <hr/>
        <div>#2023-07-29</div>
        <ul>
          <li>Po uplatnění permanentky se zobrazí hláška, kolik zbývá masáží</li>
          <li>Ke správě uživatelů přidána ikonka, jestli je uživatel aktivní</li>
          <li>Permanentky / Dárkové poukazy nastaveny aby se zobrazovali jen platný</li>
          <li>Přidáno checkbox na poukazy a permanentky - zobrazení neplatný poukazy / permanekty</li>
        </ul>
        <hr/>
        <div>#2023-04-28</div>
        <ul>
          <li>Upgrade systému: nová verze knihoven, přesunutí do nového repozitáře</li>
        </ul>
        <hr/>
        <div>#2023-02-26</div>
        <ul>
          <li>Drobný refaktoring v komponentách na platby</li>
          <li>Přidána nová funkcionalita ve Správa plateb: uživatelsky lepší změna platby pro administrátory</li>
          <li>Reset Způsob platby a Typ a cena masáže po změně datumu v kalendáři</li>
        </ul>
        <hr/>
        <div>#2023-01-27</div>
        <ul>
          <li>Rozšířena správa plateb o vytořil/a kdy, upravil/a kdy</li>
          <li>Deaktivováno políčko Číslo dokladu v kalendáři</li>
        </ul>
        <hr/>
        <div>#2022-10-05</div>
        <ul>
          <li>Filtrování cen podle délky rezervace</li>
          <li>Zakázáno hledání poukazu/permanentky na rezervacích delší než hodina</li>
        </ul>
        <hr/>
        <div>#2022-09-20</div>
        <ul>
          <li>Zakázané mezery v čísla poukazy</li>
        </ul>
        <hr/>
        <div>#2022-09-09</div>
        <ul>
          <li>Přidání více typů masáží do úpravy platby - Správa plateb</li>
          <li>Zakazano prodlužování dárkových poukazů / permanentek recepčním</li>
          <li>Anonymizace čísla poukazu - pro recepční</li>
        </ul>
        <hr/>
        <div>#2022-04-19</div>
        <ul>
          <li>Omezení přihlášení pouze na aktivní uživatele</li>
          <li>Přepracována vazba na typ platby</li>
          <li>Import docházky a docházkový list</li>
        </ul>
        <hr/>
        <div>#2022-01-13</div>
        <ul>
          <li>Omezení maximálního počtu písmen v titulku na 100 znaků</li>
        </ul>
        <hr/>
        <div>#2021-12-19</div>
        <ul>
          <li>Správa neuzavřených rezervací (jen pro adminy)</li>
          <li>Import docházkového listu (pouze pro adminy)</li>
        </ul>
        <hr/>
        <div>#2021-12-11</div>
        <ul>
          <li>Upravena adresa v PDFkách pro dárkové poukazy</li>
          <li>Správa plateb (jen pro adminy)</li>
          <li>Po zrušení platby se číslo poukazu uloží do poznámky v rezervaci</li>
        </ul>
        <hr/>
        <div>#2021-12-05</div>
        <ul>
          <li>V čísle poukazu přidána kontrola na 14 znaků</li>
          <li>V případě platby slevomatem je nyní povinné zadat číslo poukazu do "čísla poukazu"</li>
        </ul>
        <hr/>
        <div>#2021-11-17</div>
        <ul>
          <li>Přidány práva recepčním na reporty slevomatu</li>
          <li>Přidána poznámka do vyhledávání rezervací</li>
        </ul>
        <hr/>
        <div>#2021-11-13</div>
        <ul>
          <li>Přejmenování sloupce "Využito dne" na "Uplatněno dne" v Uplatněných Slevomatových reportech</li>
          <li>seřazení Uplatněných Slevomatových reporů podle data uplatnění</li>
        </ul>
        <hr/>
        <div>#2021-10-06</div>
        <ul>
          <li>Správa ceny masáží (pouze pro administrátory)</li>
        </ul>
        <hr/>
        <div>#2021-09-29</div>
        <ul>
          <li>Editace sumarizačních hodnot</li>
          <li>Kontrola zobrazení tlačítka Účtenka když je permanentka / dárkový poukaz placen na fakturu</li>
        </ul>
        <hr/>
        <div>#2021-09-25</div>
        <ul>
          <li>Seřazení řádků v sumarizacích podle nejnovějšího zadání hodnot</li>
          <li>Do výkazu práce recepčních byla přidána kontrola, na organizaci</li>
          <li>Přidán nový pokročilý report: Výkaz hotovostních tržeb za pobočku (jen pro admina)</li>
          <li>Přidaný indikátor aktuálního času</li>
        </ul>
        <hr/>
        <div>#2021-09-18</div>
        <ul>
          <li>Opravena spisová značka na účtenkách</li>
          <li>Seřazení typů masáží (stálé masáže jsou nahoře)</li>
          <li>Chyba Something went wrong nahrazena za lepší hláškou.</li>
          <li>Do dialogového okna pro přidání rezervace přidána kontrola na název rezervace a jméno se přepíše do názvu
            (pokud je zadáno)
          </li>
          <li>Když uživatel nemá vyplněno číslo recepční, neuloží se platba (platí hlavně pro správce a
            administrátory)
          </li>
          <li>Drobné opravy pokročilých reportů</li>
        </ul>
        <hr/>
        <div>#2021-08-26</div>
        <ul>
          <li>Opraveny práva pro účetní na přidávání sumarizace</li>
          <li>V sumarizacích se nově zobrazují všechny organizace + při zadávání sumarizace je možné si vybrat
            organizaci
          </li>
          <li>V pokročilích reportech jsou propojené Chrlice a Bohunice</li>
          <li>V Modřanech je přidána Prémiová masáž</li>
        </ul>
        <hr/>
        <div>#2021-08-22</div>
        <ul>
          <li>Opraveny okraje účtenek při tisku.</li>
          <li>Zneplatnění čísla poukazu pokud recepční vybere způsob platby kartou / hotově.</li>
          <li>Posunuta kontrola na placení rezervace, která ještě neproběhla.</li>
        </ul>
        <hr/>
        <div>#2021-07-25</div>
        <ul>
          <li>Generování dárkových poukazů a permanentek do PDFka</li>
          <li>Zadávání a generování sumarizačních reportů</li>
          <li>Opraven label pro datumové políčka (aby se nepřekrýval text)</li>
        </ul>
        <hr/>
        <div>#2021-07-05</div>
        <ul>
          <li>Přidáno tlačítko na smazání zákazníka</li>
          <li>Do výpisu zákazníků bylo přidáno políčko počet jeho rezervací</li>
        </ul>
        <hr/>
        <div>#2021-06-28</div>
        <ul>
          <li>Na permanentku bylo přidané nové textové pole "poznámka"</li>
          <li>Přidáné tlačítko na zrušení platby v rezervacích (jen pro administrátora)</li>
        </ul>
        <hr/>
        <div>#2021-06-16</div>
        <ul>
          <li>Na každou platbu lze přidat "fakturační údaje"</li>
        </ul>
        <hr/>
        <div>#2021-06-07</div>
        <ul>
          <li>Přidán loader na dialogové okno pro úpravu rezervace + optimalizace načítání</li>
        </ul>
        <hr/>
        <div>#2021-06-06</div>
        <ul>
          <li>Přidán adresář zákazníků</li>
          <li>Kontrolor může prohlížet historii permanentek</li>
          <li>Upraven formulář na přidávání permanentek / dárkových poukazů</li>
        </ul>
        <hr/>
        <div>#2021-05-29</div>
        <ul>
          <li>Optimalizace práv pro Správce</li>
          <li>Přidána role Kontrolor</li>
          <li>Povoleno hromadné vytváření dárkových poukazů (nově nevyskakuje okno pro tisk účtenky v permanentkách a
            dárkových poukazech)
          </li>
          <li>Upraven formát účtenky</li>
          <li>Přidány reporty "Platba kartou za pobočku" recepčním</li>
        </ul>
        <hr/>
        <div>#2021-05-26</div>
        <ul>
          <li>Správce a administrátor můžou editovat název permanentky</li>
          <li>Úprava práv pro Správce</li>
          <li>Opraveno generování čísla účtenek</li>
          <li>Přidáno tlačítko "najít" do kalendáře, které hledá platbu podle čísla poukazu / permanentky</li>
          <li>Opraveny okraje účtenky</li>
          <li>Opraveno datum od - do u přidávání rezervací</li>
        </ul>
        <hr/>
        <div>#2021-05-22</div>
        <ul>
          <li>Aktualizace knihovny na requesty.</li>
          <li>
            Výběr organizace je přesunut výše nad aplikací, nyní se lze přepínat z jakéhokoliv místa mezi organizacemi.
          </li>
          <li>Masér má zpřístupněné jeho vlastní reporty výkazu práce</li>
          <li>Nová role účetní (stejná práva jako recepční, navíc může zadávat poukazy / permanentky na fakturu).</li>
          <li>
            Kontrola zadaných polí v permanentkách / dárkových poukazech - nyní by nemělo jít vytvořit poukaz bez platby
            nebo nadpisu.
          </li>
          <li>
            Masér nebo recepční nemusí v reportu zadávat jméno maséra / recepční - když ho nezadá bere se to jako jeho
            vlastní report.
          </li>
          <li>Po uplatnění masáže (slevomat, permanentka, dárkový poukaz) se automaticky uloží rezervace.</li>
          <li>Rezervace jsou tmavším odstínem když jsou zavřené</li>
          <li>Zlepšení počítání využitých a rezervovaných permanentek</li>
          <li>Nová role: uklízeč/ka</li>
          <li>Odstraněno číslo poukazu z dialogového okna pro vytváření dárkových poukazů / permanentek</li>
          <li>Administrátor může editovat čas rezervace po uzavření.</li>
          <li>Přepínání v kalendáři mezi měsíčním a týdením výpisem</li>
        </ul>
        <hr/>
        <div>#2021-05-03</div>
        <ul>
          <li>Zneaktivnění tlačítek dělat na masáži jen pro zaplacené / uplatněné masáže</li>
          <li>Opraveno řazení ve vyhledávání v kalendáři (+ vylepšen formát data na čitelnější tvar)</li>
          <li>
            V reportu "Výkaz práce maséra" upraven výběr, aby se zobrazovali v reportu jen využité permanentky / dárkové
            poukazy
          </li>
        </ul>
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "2em"
  }
}));


export default SystemNews;
