import React from "react";
import {Grid, TableCell} from "@mui/material";
import EnhancedIconButton from "../enhanced/EnhancedIconButton";
import {ArrowBack, Delete, Edit} from "@mui/icons-material";
import EnhancedTable from "../enhanced/EnhancedTable";
import alertHelper from "../../helpers/alertHelper";
import {deleteCustomer} from "../../features/modalEditSlice";
import {useDispatch} from "react-redux";
import EnhancedTableIconButton from "../enhanced/EnhancedTableIconButton";

const CustomerTable = (props) => {
  const dispatch = useDispatch();

  const {
    filteredCustomersList,
    handleBackBtn,
    className,
    setOpen,
    setSelectedCustomer,
    searchValue
  } = props;

  const handleEdit = (customer) => {
    setSelectedCustomer(customer);
    setOpen(true);
  };

  const handleDelete = (customer) => {
    alertHelper.confirm({
      message: "Opravdu si přejete smazat zákazníka " + customer.firstName + " " + customer.lastName + "?",
      callback: () => dispatch(deleteCustomer({
        id: customer?.id,
        searchText: searchValue
      }))
    });
  };

  function isDisabledDelete(customer)
  {
    return customer['count'] > 0;
  }

  const headerList = [
    { align: "left", label: "" },
    { align: "left", label: "Jméno" },
    { align: "left", label: "Telefon" },
    { align: "left", label: "Rezervace" }
  ];

  const renderRowCells = (customer) => (
    <>
      <TableCell>
        <EnhancedTableIconButton
          authority="customer.edit"
          ariaLabel="edit row"
          tooltip="Upravit"
          size="small"
          Icon={Edit}
          onClick={() => handleEdit(customer)}
        />
      <EnhancedTableIconButton
        authority="customer.delete"
        ariaLabel="edit row"
        tooltip="Smazat"
        size="small"
        Icon={Delete}
        disabled={isDisabledDelete(customer)}
        onClick={() => handleDelete(customer)}
      />
      </TableCell>
      <TableCell align="right">{customer["fullName"]}</TableCell>
      <TableCell align="right">{customer["phone"]}</TableCell>
      <TableCell align="right">{customer["count"]}</TableCell>
    </>
  );

  return (
    <>
      <Grid item className={className}>
        <EnhancedIconButton ariaLabel="back icon" tooltip="Zpět" Icon={ArrowBack} onClick={handleBackBtn} />
        <EnhancedTable headerList={headerList} dataList={filteredCustomersList} renderRowCells={renderRowCells} />
      </Grid>
    </>
  );
};

export default CustomerTable;
