import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getFetchURL } from "../helpers/actionHelpers";
import { getSessionCompanyId } from "../helpers/sessionHelper";

const initialState = {
  masseurList: [],
  receptionistList: [],
  typeOfMassageList: [],
  companyList: [],
  roomList: [],
  aclRoleList: [],
  isRoomListFetching: false
};

export const fetchReceptionists = createAsyncThunk("lists/fetchReceptionists", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/user/company/receptionists/get/${getSessionCompanyId()}`));

  return response.data;
});

export const fetchMasseurs = createAsyncThunk("lists/fetchMasseurs", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/user/company/masseurs/get/${getSessionCompanyId()}`));

  return response.data;
});

export const fetchRooms = createAsyncThunk("lists/fetchRooms", async (id, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/room/company/get/${id}`));
  return response.data;
});

export const fetchAclRoles = createAsyncThunk("lists/roles", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL("/acl-role/get-limited"));
  return response.data;
});

export const fetchCompanyList = createAsyncThunk("lists/fetchCompanyList", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL("/company/user-allowed/get"));
  return response.data;
});

export const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReceptionists.fulfilled, (state, action) => {
        state.receptionistList = action.payload;
      })
      .addCase(fetchMasseurs.fulfilled, (state, action) => {
        state.masseurList = action.payload;
      })
      .addCase(fetchRooms.pending, (state, action) => {
        state.isRoomListFetching = true;
      })
      .addCase(fetchRooms.fulfilled, (state, action) => {
        state.roomList = action.payload;
        state.isRoomListFetching = false;
      })
      .addCase(fetchAclRoles.fulfilled, (state, action) => {
        state.aclRoleList = action.payload;
      })

      .addCase(fetchCompanyList.fulfilled, (state, action) => {
        state.companyList = action.payload; // ???
      })
  }
});

export default listsSlice.reducer;
