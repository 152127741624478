import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h1">404: Stránka nenalezena</Typography>
            <img alt="Under development" className={classes.image} src="/images/undraw_page_not_found_su7k.svg" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: "center"
  },
  image: {
    marginTop: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 560
  }
}));

export default NotFound;
