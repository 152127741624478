import React from "react";
import { Divider, Drawer } from "@mui/material";
import {
  CalendarMonth,
  Business,
  ClearAll,
  Description,
  FileCopy,
  List,
  People,
  Person,
  VpnKey,
  Assessment,
  MarkunreadMailbox,
  Money,
  AttachMoney,
  Close,
  EditAttributes,
  HomeWork,
  Announcement
} from "@mui/icons-material";
import Calendar from "@mui/icons-material/CalendarToday";
import { makeStyles } from '@mui/styles';
import PropTypes from "prop-types";
import Profile from "./Profile";
import SidebarNav from "./SidebarNav";

const Sidebar = (props) => {
  const { open, variant, onClose } = props;
  const classes = useStyles();

  const pages = [];

  pages.push(
    {
      title: "Novinky v systému",
      href: "/system-news",
      icon: <Announcement />,
      authority: "changelog.view"
    },
    {
      title: "Kalendář",
      href: "/calendar",
      icon: <Calendar />,
      authority: "calendar.view"
    },
    {
      title: "Kalendář",
      href: "/customer-calendar",
      icon: <CalendarMonth />,
      authority: "customer calendar.view"
    },
    {
      title: "Zákazníci",
      href: "/customer-management",
      icon: <Person />,
      authority: "customer.view"
    },
    {
      title: "Správa poukazů",
      href: "/voucher-management",
      icon: <Description />,
      authority: "voucher.view"
    },
    {
      title: "Správa permanentek",
      href: "/pass-log-management",
      icon: <VpnKey />,
      authority: "passlog.view"
    },
    {
      title: "Sumarizace",
      href: "/sum-management",
      icon: <Assessment />,
      authority: "companysum.view"
    },
    {
      title: "Správa uživatelů",
      href: "/user-management",
      icon: <People />,
      authority: "user.view"
    },
    {
      title: "Správa společnosti",
      href: "/company-management",
      icon: <Business />,
      authority: "company.view"
    },
    {
      title: "Správa plateb",
      href: "/payment-management",
      icon: <AttachMoney />,
      authority: "payment.view"
    },
    {
      title: "Neuzavřené rezervace",
      href: "/not-paid-reservations",
      icon: <Close />,
      authority: "calendar.reservation-not-paid"
    },
    {
      title: "Reporty",
      href: "/report-management",
      icon: <FileCopy />,
      authority: "report.view"
    },
    {
      title: "Pokročilé reporty",
      href: "/adv-report-management",
      icon: <MarkunreadMailbox />,
      authority: "all"
    },
    {
      title: "Správa ceníků",
      href: "/pricing-management",
      icon: <Money />,
      authority: "all"
    },
    {
      title: "Agenda",
      href: "/agenda",
      icon: <List />,
      authority: "agenda.view"
    },
    {
      title: "Agenda",
      href: "/cleaner-agenda",
      icon: <ClearAll />,
      authority: "cleaner.view"
    },
    {
      title: "Import docházky",
      href: "/import-attendance",
      icon: <EditAttributes />,
      authority: "attendance.view"
    },
    {
      title: "Docházka",
      href: "/attendance-list",
      icon: <HomeWork />,
      authority: "attendance.view"
    },
    {
      title: "Informace o HPP",
      href: "/informace-o-prav-vztahu-zalozenem-na-hpp",
      icon: <HomeWork />,
      authority: "settings.view"
    }
  );

  return (
    <Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant}>
      <div className={classes.root}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav pages={pages} onClose={onClose} />
        <Divider className={classes.divider} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)"
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  }
}));

export default Sidebar;
