import { Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";
import EnhancedTextField from "../enhanced/EnhancedTextField";
import { addMassagePrice } from "../../features/pricingSlice";
import alertHelper from "../../helpers/alertHelper";
import { getSessionCheckedCompany } from "../../helpers/sessionHelper";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";

function PriceAddDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { open, setOpenNew, massageType } = props;
  const [formState, setFormState] = useState({});

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleClose = () => {
    setOpenNew(false);
    setFormState({});
  };

  const handleSave = () => {
    if (!formState.price) {
      alertHelper.error({ message: "Musíte zadat cenu masáže!" });
      return false;
    }

    if (!formState.price) {
      alertHelper.error({ message: "Musíte zadat plat pro maséra!" });
      return false;
    }

    const apiData = {
      companyId: getSessionCheckedCompany(),
      massageTypeId: massageType.id,
      price: formState.price,
      salary: formState.salary
    };

    dispatch(addMassagePrice(apiData));

    handleClose();
  };

  return (
      <div className={classes.root}>
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
          <EnhancedDialogTitle handleClose={handleClose} nameLabel="Vložit novou cenu" />
          <DialogContent>
            <Grid container spacing={3} sx={{marginTop: -2.3}}>
              <Grid item xs={6}>
                <EnhancedTextField name="price" value={formState.price} label="Cena" onChange={handleChange} />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField name="salary" value={formState.salary} label="Plat maséra" onChange={handleChange} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <EnhancedConfirmButton handleOk={handleSave} okLabel="Přidat cenu" />
          </DialogActions>
        </Dialog>
      </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default PriceAddDialog;
