import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {attendanceSettingsData, deleteAttendanceUserDay} from "../../features/usersSlice";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";
import EnhancedIconButton from "../enhanced/EnhancedIconButton";
import {Remove} from "@mui/icons-material";
import alertHelper from "../../helpers/alertHelper";

function UserAttendanceSettingsDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const attendanceSettingsList = useSelector((state) => state.users.attendanceSettingsList);

  const { open, setOpen, user } = props;

  useEffect(() => {
    open && dispatch(attendanceSettingsData(user.id));
  }, [open, user, dispatch]);

  function handleClose() {
    setOpen(false);
  }

  const handleDeleteUserAttendanceDay = (attendanceUserDay) => {
    alertHelper.confirm({
      message: "Opravdu si přejete tento pracovní den uživatele?",
      callback: () => dispatch(deleteAttendanceUserDay({
        id: attendanceUserDay.id,
        userId: user.id
      }))
    });
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Docházkové dny uživatele" />
        <DialogContent>
          <TableContainer>
            {attendanceSettingsList.length === 0 ? null : (
                <Table aria-label="passlog table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Den v týdnu</TableCell>
                      <TableCell align="center">Počet hodin</TableCell>
                      <TableCell align="center">Platnost do</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {attendanceSettingsList.map((attendanceUserDay, i) => (
                        <TableRow key={i}>
                          <TableCell align="center">{attendanceUserDay.dayInWeekLabel}</TableCell>
                          <TableCell align="center">{attendanceUserDay.timeCount}</TableCell>
                          <TableCell align="center">{attendanceUserDay.dateExpire}</TableCell>
                          <TableCell align="right">
                            <EnhancedIconButton
                                onClick={() => handleDeleteUserAttendanceDay(attendanceUserDay)}
                                authority="user.delete"
                                ariaLabel="attendance row"
                                size="small"
                                className={classes.remove}
                                tooltip="Odstranění docházky"
                                Icon={Remove}
                                sx={styles.remove}
                            />
                          </TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
            )}
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const styles = {
  remove: {
    color: "#dc3545"
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default UserAttendanceSettingsDialog;