export const buttonStyles = {
    ok: {
        color: "white",
        background: "#8bc34a",
        "&:hover": {
            color: "white",
            background: "#476C23FF"
        }
    },
    pay: {
        color: "white",
        background: "#007bff",
        "&:hover": {
            color: "white",
            background: "#0069d9"
        }
    },
    apply: {
        color: "white",
        background: "#ffc107",
        "&:hover": {
            color: "white",
            background: "#e0a800"
        }
    },
    delete: {
        color: "white",
        background: "#dc3545",
        "&:hover": {
            color: "white",
            background: "#B22222"
        }
    },
    notTakePlace: {
        color: "white",
        background: "#941396",
        "&:hover": {
            color: "white",
            background: "#721396"
        }
    },
    exportBill: {
        color: "white",
        background: "#17a2b8",
        "&:hover": {
            color: "white",
            background: "#138496"
        }
    },
    magic: {
        color: "white",
        background: "#ff9900",
        "&:hover": {
            color: "white",
            background: "#cc7a00"
        }
    },
    dNone: {
        display: "none"
    }
}